<!-- 机构 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getOrgList,
    updateOrgQyzt,
    deleteOrg,
    addOrg,
    editOrg,
  } from "@/api/admin/base/org.js"


  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,

    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "控制面板",
            active: true
          }
        ],
        checked: true,
        exportTitle: "机构导出",
        importTitle: "机构导入",
        showmodal: false,
        dialogTitle: "添加机构",
        module: "JG",
        tableList: [],
        disabled: false,
        addForm: {
          jgdm: "",
          jgmc: "",
          jgsx: "",
          qyzt: true,
          jgjb: "",
        },
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0
        }
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteOrg(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        getOrgList(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 改变启用状态
      changeQyzt(obj) {
        let {
          sid,
          qyzt
        } = obj
        updateOrgQyzt({
          sid: sid,
          qyzt: qyzt
        }).then(res => {
          console.log(res)
        })
      },
      // 添加类别
      addOrg() {
        let formDate = {
          jgdm: this.addForm.jgdm,
          jgmc: this.addForm.jgmc,
          jgsx: this.addForm.jgsx,
          qyzt: this.addForm.qyzt,
          jgjb: this.addForm.jgjb,
        }
        addOrg(formDate).then(res => {
          if (res.status) {
            this.success()
          }

          console.log(res)
        })
      },
      editOrg() {
        let formDate = {
          jgdm: this.addForm.jgdm,
          jgmc: this.addForm.jgmc,
          jgsx: this.addForm.jgsx,
          qyzt: this.addForm.qyzt,
          jgjb: this.addForm.jgjb,
          sid: this.addForm.sid
        }
        editOrg(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })
        this.addForm = {
          jgdm: "",
          jgmc: "",
          jgsx: "",
          qyzt: true,
          jgjb: "",

        }
        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加机构"
        this.addForm = {
          jgdm: "",
          jgmc: "",
          jgsx: "",
          qyzt: true,
          jgjb: "",

        }
        this.getBm(this.module, "jg").then(e => {
          this.addForm.jgdm = e
        })
      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改机构"
        this.disabled = true
      },
      submit() {
        if (this.addForm.sid) {
          this.editOrg()
        } else {
          this.addOrg()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }
    },
    mounted() {
      this.getList()
    }
  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加机构</b-button>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module: module})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 5%;">序号</th>
                    <th style="width: 10%;">机构编码</th>
                    <th>机构名称</th>
                    <th>属性</th>
                    <th style="width: 8%;">修改人</th>
                    <th>修改时间</th>
                    <th style="width:8%">是否启用</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.jgdm}}</td>
                    <td><a :href="'/admin/baseCompany?jgdm='+obj.jgdm" class="font-blue">{{obj.jgmc}}</a></td>
                    <td> {{obj.jgsx}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td>
                      <b-form-checkbox v-model="obj.qyzt" switch class=" switch-check" @change="changeQyzt(obj)">
                      </b-form-checkbox>
                    </td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.modifyTime}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.jgmc)"></i></td>
                  </tr>


                </tbody>
              </table>

            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->
    <!-- 添加机构 -->
    <b-modal id="addOrganization" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18"
      hide-footer>
      <div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>机构代码：</label>
              <div class=" col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" maxlength="6" :disabled="disabled" v-model="addForm.jgdm"
                  placeholder="使用国家统一机构编码" class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>机构名称：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" value="" maxlength="30" v-model="addForm.jgmc" placeholder="请输入机构名称"
                  class="form-control w-100 h30 " />
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>机构属性：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.jgsx">
                  <option value="" disabled="">请选择机构属性</option>
                  <option value="政府机构">政府机构</option>
                  <option value="事业单位">事业单位</option>
                  <option value="国有企业">国有企业</option>
                  <option value="民营企业">民营企业</option>
                  <option value="非营利性组织">非营利性组织</option>
                  <option value="其他">其他</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>机构级别：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.jgjb">
                  <option value="" disabled>请选择机构级别</option>
                  <option value="省级机构">省级机构</option>
                  <option value="国家直属机构">国家直属机构</option>
                  <option value="地市级机构">地市级机构</option>
                  <option value="区县级机构">区县级机构</option>
                  <option value="其他机构">其他机构</option>
                </select>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">是否启用：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.qyzt"
                    value=true class="form-check-input"><label for="exampleRadios1" class="form-check-label">启用
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="qyzt" id="exampleRadios1" v-model="addForm.qyzt"
                    value=false class="form-check-input"><label for="exampleRadio1" class="form-check-label">不启用</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item>
            <div class="d-flex check-distri mb-3"><label class="w-15">联系人：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="text" name="" id="" value="" v-model="addForm.lxrxm" placeholder="联系人姓名"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>联系电话：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="tel" name="" id="" value="" v-model="addForm.lxrdh" placeholder="联系电话"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>电子邮箱：</label>
              <div class="col-sm-10 p-0 flexList">
                <input type="email" name="" id="" value="" v-model="addForm.dzxx" placeholder="联系人电子邮箱"
                  class="form-control w-100 h30 " />

              </div>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束 -->
  </Layout>
</template>
